.core_bg {
    width: 80vw;
    position: relative;
    display: flex;
    flex-direction: column;
    font-family: inter;
    height: auto;
    gap: 10px;
}

.title {
    width: 38vw;
    position: relative;
    font-size: 48px;
    line-height: 100%;
    font-weight: 200;
}

.description {
    width: 38vw;
    position: relative;
    font-size: 24px;
    line-height: 100%;
    font-weight: 200;
    margin: unset;
}

.left {
    align-items: flex-start;
    text-align: left;
}

.right {
    align-items: flex-end;
    text-align: right;
}

/*Check Media Query Changes*/
@media screen and (max-width:1360px){
    .title, .description {
        width: 100%;
    }
    .left, .right{
        align-items: center;
        text-align: center;
    }
}

@media screen and (max-width: 895px){
    .title {
        width: auto;
        font-size: 40px;
    }

    .description {
        font-size: 20px;
        width: auto;
    }
}