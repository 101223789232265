.universalButtonType1Style {
    cursor: pointer;
    height: 50px;
    width: 250px;
    position: relative;
    border-radius: 36px;
    display: flex;
    text-align: center;
    font-size: 20px;
    font-family: Inter;
    align-items: center;
    justify-content: center;
    transition: 0.125s ease;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.primary {
    background-color: #0084ff;
    color: white;
}

.primary:hover {
    background-color: #229BFF;
}

.primary:active {
    transform: scale(.97);
    background-color: #0C71CF;
}

.secondary {
    background-color: white;
    color: #0084ff;
    border-width: 2px;
    border-color: #D3D3D3;
    border-style: solid;
}

.secondary:hover {
    border-color: #0084ff;
    color: #108CFF;
}

.secondary:active {
    border-color: #0C71CF;
    color: #0C71CF;
    transform: scale(0.97);
}

.caution {
    background-color: white;
    color: #9C9C9C;
    border-width: 2px;
    border-color: #D3D3D3;
    border-style: solid;
}

.caution:hover {
    transition: 0.5s ease;
    transition-delay: 0.1s;
    color: #FF1B0F;
}

.caution:active {
    transition: 0.125s ease;
    border-color: #FF1B0F;
    color: #FF1B0F;
    transform: scale(0.97);
}

.danger {
    background-color: #FF1B0F;
    color: white;
}

.danger:hover {
    background-color: #FF3D2B;
}

.danger:active {
    transform: scale(.97);
    background-color: #C7150C;
}