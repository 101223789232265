@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,200;0,500;0,700;1,400&display=swap');

html body {
    width: 100vw;
    overflow-x: hidden;
}

h1, h2, h3, h4, p {
    margin: unset;
}

body {
    margin: 0;
    line-height: normal;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}