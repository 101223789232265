.comingSoon {
    position: relative;
    font-size: 48px;
    line-height: 100%;
    display: flex;
    color: #5b5b5b;
    width: 100%;
    height: max-content;
    padding-left: 8px;
}

.features {
    width: 100vw;
    min-height: 100vh;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 80px;
    overflow-x: hidden;
    padding: 5vh 0vw 5vh 0vw;
}

.hero {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    overflow: hidden;
}

.heroPanel {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10vh;
    overflow:hidden;
}

.titlePanel {
    display: flex;
    width: max-content;
    position: relative;
    height: max-content;
    text-align: left;
    flex-direction: column;
    align-items: center;
    transform: scale()
}

.imageCloudTop {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -50%);
    height: auto;
    width: auto;
    object-fit: cover;
}

.imageCloudBottom {
    position: absolute;
    bottom: 0%;
    left: 0%;
    transform: translate(-30%);
    height: auto;
    width: auto;
    object-fit: cover;
}

.title {
    white-space: normal;
    position: relative;
    font-size: 128px;
    line-height: 100%;
    font-weight: 500;
    display: flex;
    align-items: flex-end;
    height: max-content;
    width: max-content;
    color: #5c5c5c;
    gap: 20px;
}

.titleBottom {
    padding-bottom: 60px;
}

.subtitle {
    padding-top: 50px;
    position: relative;
    line-height: 100%;
    text-align: center;
    font-size: 34px;
    color: rgba(91, 91, 91, 0.62);
}

.salutation {
    position: relative;
    text-align: center;
    font-size: 128px;
    line-height: 100%;
    color: #5b5b5b;
    padding-bottom: 10vh;
}

.pageContent {
    width: 100vw;
    position: relative;
    background: linear-gradient(160.05deg, rgba(251, 0, 255, 0.13), rgba(14, 227, 227, 0.08) 37.5%, rgba(19, 236, 218, 0.11) 63%, rgba(0, 200, 255, 0.3)), #f6f8ff;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: Inter;
}

@media screen and (max-width: 1360px) {
    .salutation {
        width: 80%;
    }
}

@media screen and (max-width: 940px){
    .welcomeTo {
        font-weight: 400;
    }

    .title {
        display: flex;
        flex-direction: column;
        gap: 0px;
    }

    .titleBottom {
        flex-direction: row;
        gap: 10px;
    }

    .titlePanel {
        text-align: center;
        align-items: center;
    }
}

@media screen and (max-width: 895px) {
    .features {
        gap: 150px;
    }
}

@media screen and (max-width: 600px) {
    .salutation {
        font-size: 88px;
    }
    .titleBottom {
        font-size: 100px;
    }
}

@media screen and (max-height: 500px){
    .titlePanel{
        transform: scale(0.8s);
    }

    .titleBottom {
        padding-bottom: 0px;
        margin-bottom: -10vh;
    }
}